import { FunctionComponent } from "react";
import IconButton, { IconButtonSVG } from "./components/IconButton";
import ImageGallery from "./components/ImageGallery";
import styles from './App.module.scss';

const App: FunctionComponent = (): React.ReactElement => {

  return <div className={styles.app}>
    <div className="flex justify-center items-center flex-col pt-12 px-4 mx-auto">
      <div className="lg:px-16 xl:px-24 2xl:px-64 py-2 relative text-center mt-1 lg:mt-6">
        <div className="h-24 w-24 lg:h-32 lg:w-32 bg-[#c83737ff] rounded-full absolute top-[-2.5rem] lg:top-[-3.5rem] left-0 right-0 ml-auto mr-auto">
          <img src="https://renokarwei-roberto.be/assets/logo.svg" alt="logo" className="p-4 lg:p-6" />
        </div>
        <div className="rounded overflow-hidden shadow-2xl bg-gray-100">

          <img className="w-full h-60 md:h-70 xl:h-80 object-cover object-center" src="https://renokarwei-roberto.be/assets/header.jpg" alt="Mountain" />
          <div className="px-6 lg:px-12 py-4">
            <div className="font-bold text-xl lg:text-2xl mb-2 text-[#c83737ff]">Renovatie- en karweiwerken Roberto</div>
            <p className="text-gray-700 text-sm lg:text-base text-center lg:text-justify">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos corporis eius accusantium expedita in consectetur hic earum ullam aut, pariatur accusamus? Temporibus nostrum at placeat ipsam. Nesciunt dolorum laboriosam modi?
              Voluptates recusandae accusamus vero magni hic est libero consectetur deleniti quibusdam, sapiente aut. Ipsa, blanditiis non quisquam aliquid recusandae nulla assumenda inventore cum iste et quae sapiente doloribus, veniam eligendi.
              Alias aliquam dolores asperiores inventore dolorem ut corrupti doloremque. Veniam est voluptatum, magnam inventore dolore, maxime dicta, in ipsam tenetur similique repellat modi obcaecati voluptas. Fugit error impedit libero. Soluta!
            </p>
          </div>

          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-slate-300 mx-12" />

          <div className="mt-2 text-gray-900">
            <div className="font-semibold text-lg">Robert Thys</div>
            <div className="text-lg">info@renokarwei-roberto.be</div>
            <div className="text-lg">+32 473 22 40 45</div>
            <div className="text-lg">BE 0798.199.637</div>
          </div>

          <div className="flex justify-center mt-5 mb-2">
            <IconButton bgColor="bg-green-500" icon={IconButtonSVG.Whatsapp} fill='currentColor' onClick={() => window.open('https://wa.me/32473224045')} />
            <IconButton bgColor="bg-blue-500" icon={IconButtonSVG.Mail} stroke='currentColor' onClick={() => window.open('mailto:info@renokarwei-roberto.be')} />
            <IconButton bgColor="bg-slate-500" icon={IconButtonSVG.Phone} stroke='currentColor' onClick={() => window.open('tel:+32473224045')} />
          </div>


        </div>
      </div>
      <ImageGallery />





    </div>

    <footer className="p-4 md:px-6 md:py-8">
      <span className="block text-md text-gray-900 text-center dark:text-gray-400">
        Designed by <a href="https://jelleceulemans.be/" className="hover:underline" target='_blank' rel='noreferrer'>Jelle Ceulemans</a>
      </span>
    </footer>
  </div>
};

export default App;