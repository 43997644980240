export class PictureService {
    getGalleryImages = async (): Promise<string[]> => {
        try {
            //https://files.jellec.be/files?domain=jellec.be&path=/images

            const response = await fetch(`https://files.renokarwei-roberto.be/files?domain=renokarwei-roberto.be&path=/assets/gallery`);
            const jsonResponse: string[] = await response.json();
            return jsonResponse;
        } catch {
            return [];
        }
    }
}