import { FunctionComponent, useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { PictureService } from "../services/PictureService";
import 'react-photo-view/dist/react-photo-view.css';

const ImageGallery: FunctionComponent<{}> = (props: {}): React.ReactElement => {
    const [images, setImages] = useState<string[]>([]);
    const pictureService = new PictureService();

    useEffect(() => {
        pictureService.getGalleryImages().then((images: string[]) => setImages(images));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="overflow-hidden text-neutral-700">
            <div className="mx-auto py-2 lg:px-4 2xl:px-36 lg:pt-12">
                <div className="flex flex-wrap md:-m-2">
                    <PhotoProvider>
                        {images.map((image: string, index: number) => {
                            return (
                                <div key={index} className="flex w-full sm:w-1/2 xl:w-1/3 flex-wrap hover:opacity-50 cursor-pointer transition-opacity duration-300">
                                    <div className="w-full p-1 md:p-2 h-60 lg:h-80">
                                        <PhotoView key={index} src={image}>
                                            <img
                                                alt={`Construction ${index}`}
                                                className="block h-full w-full rounded-lg object-cover object-center"
                                                src={image} />
                                        </PhotoView>
                                    </div>
                                </div>
                            )
                        })}
                    </PhotoProvider>

                </div>
            </div>
        </div>
    );
};

export default ImageGallery;